<template>
  <div class="social-active-achievements">
    <Preloader v-if="isStatementsLoading" />

    <div class="social-active-achievements__item" v-else>
      <p class="social-active-achievements-add__description">
        Согласно
        <router-link
          :to="{ name: 'EventPage', params: { id: 1 } }"
          target="_blank"
        >
          Положению Конкурса,
        </router-link>
        &nbsp;сканы достижений необходимо загружать за {{ statementYear }} года
      </p>
      <p class="social-active-achievements-add__description">
        Все документы необходимо загружать с персонального компьютера или
        ноутбука во избежание технических неполадок
      </p>
      <div class="social-active-achievements__btn">
        <Button
          @click="sendStatements"
          v-if="!isDisabledSend"
          :disabled="noneAchievements"
          :loading="isBtnLoading"
        >
          Подать заявку
        </Button>
        <Button
          @click="openPopup"
          secondary
          :loading="isDeleteBtnLoading"
          v-if="rollbackAllowed"
        >
          Отказ от участия
        </Button>
      </div>

      <div class="social-active-achievements__info">
        <p>
          Кол-во загруженных достижений:
          <b>{{ achievementsList.length }} / 50</b>
        </p>
        <p>
          Кол-во набраных баллов:
          <b>{{ pointsCount }}</b>
        </p>
      </div>
      <SocialActiveAchievementItem
        v-for="item in achievementsList"
        :key="moment(item.created_at).unix()"
        :data="item"
        :currentStatementId="currentStatementId"
        :project-id="currentProject?.id"
        :all-fields="allFields"
        :current-tree-item="currentTreeItem"
        :currentStatus="currentStatus"
        :statementYear="statementYear"
      />
      <!--      <div-->
      <!--        class="social-active-achievements__warning"-->
      <!--        v-if="!isDisabled"-->
      <!--        style="margin-top: 20px"-->
      <!--      >-->
      <!--        Чтобы загрузить достижения сначала нужно их добавить в портфолио в-->
      <!--        разделе-->
      <!--        <span>-->
      <!--          <router-link :to="{ name: 'Portfolio' }">Мое портфолио</router-link>-->
      <!--        </span>-->
      <!--      </div>-->
      <div
        class="social-active-achievements__toggle"
        @click="addAchievement"
        v-if="addAchievementsShow"
      >
        Добавить достижение
      </div>

      <SocialActiveAchievementAdd
        v-if="achievementIsShown"
        @delete_achievement="achievementIsShown = false"
        :all-fields="allFields"
        @save_achievement="
          (value) => {
            saveAchievement(value);
          }
        "
        :achievementsList="filteredPortfolioList"
        :currentStatementId="currentCategoryData.id || statementId"
      />
      <div
        class="social-active-achievements__btn"
        v-if="achievementsList.length > 0"
      >
        <Button
          @click="sendStatements"
          :loading="isBtnLoading"
          :disabled="noneAchievements"
          v-if="!isDisabledSend"
        >
          Подать заявку
        </Button>
        <Button
          @click="openPopup"
          secondary
          :loading="isDeleteBtnLoading"
          v-if="rollbackAllowed"
        >
          Отказ от участия
        </Button>
        <Button secondary v-if="false">Сохранить как черновик</Button>
      </div>
    </div>
  </div>
</template>

<script>
import SocialActiveAchievementAdd from "@/components/TransportGrantSection/SocialActiveAchievementAdd";
import Button from "@/components/Blocks/Button";
import SocialActiveAchievementItem from "@/components/TransportGrantSection/SocialActiveAchievementItem";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import Preloader from "@/components/Blocks/Preloader";
import { parseChildrenTrees, parseParentTrees } from "@/utils/helpers";

export default {
  name: "SocialActiveAchievements",
  components: {
    Preloader,
    SocialActiveAchievementItem,
    Button,
    SocialActiveAchievementAdd,
  },
  data() {
    return {
      achievementIsShown: false,
      isStatementsLoading: false,
      isBtnLoading: false,
      fieldsName: ["Область деятельности", "Критерий", "Уровень", "Статус"],
      currentTreeItem: [],
    };
  },
  props: ["currentProject"],
  computed: {
    currentStatus() {
      return this.currentCategoryData?.status?.key
        ? this.currentCategoryData?.status?.key
        : 0;
    },
    noneAchievements() {
      return this.achievementsList.length === 0;
    },
    isEditableStatement() {
      return (
        this.currentCategoryData?.status?.key === 0 ||
        this.currentCategoryData?.status?.key === 35
      );
    },
    filteredPortfolioList() {
      let sortedArr = JSON.parse(JSON.stringify(this.portfolioList));

      return sortedArr
        .filter((item) => {
          return !this.alreadyUsedPortfolio?.includes(item.id);
        })
        .filter((item) => {
          return item.year >= moment().subtract(1, "year").format("YYYY");
        });
    },
    alreadyUsedPortfolio() {
      return this.achievementsList?.map((item) => {
        return item?.id;
      });
    },
    isDisabled() {
      let boolean;
      if (!this.statementId) {
        if (this.validPeriodForRollback) {
          boolean = false;
        } else if (this.validPeriodFor35Status) {
          boolean =
            this.currentCategoryData?.status?.key !== 35 &&
            this.currentCategoryData?.status?.key !== 50;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    isDisabledSend() {
      let boolean;
      if (!this.statementId) {
        if (this.validPeriodForRollback) {
          boolean = !this.isEditableStatement;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    statuses() {
      return JSON.parse(
        JSON.stringify(
          this.storage?.systemInfo?.list_statuses[
            `${this.currentCategoryData?.info_project?.key}`
          ] ?? []
        )
      );
    },
    rollbackAllowed() {
      let boolean;
      if (this.validPeriodForRollback) {
        if (
          this.currentProject?.id === 1 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus == "0" ||
            this.currentStatus == "1" ||
            this.currentStatus == "50";
        }
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus == "0" ||
            this.currentStatus == "1" ||
            this.currentStatus == "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus == "0" ||
            this.currentStatus == "1" ||
            this.currentStatus == "50";
        }
        if (this.currentProject?.id === 4) {
          boolean =
            this.currentStatus == "0" ||
            this.currentStatus == "1" ||
            this.currentStatus == "50";
        }
      } else if (this.validPeriodFor35Status) {
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus == "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus == "50";
        }
      } else boolean = false;

      return this.isOldApplication ? false : boolean;
    },
    hasPortfolioList() {
      return this.portfolioList?.length > 0;
    },
    hasNotUsedPortfolio() {
      return this.filteredPortfolioList?.length > 0;
    },
    addAchievementsShow() {
      return (
        !this.isDisabled &&
        // this.hasPortfolioList &&
        // this.hasNotUsedPortfolio &&
        // this.currentCategoryData?.status?.key !== 50 &&
        this.currentCategoryData?.status?.key !== 40 &&
        this.currentCategoryData?.status?.key !== 27 &&
        this.currentCategoryData?.status?.key !== 100
      );
    },
    currentStatementId() {
      return this.currentCategoryData.id || this.statementId;
    },
    currentStatement() {
      let statement = false;
      if (this.statementsList && this.statementsList.length > 0) {
        statement = this.statementsList.find((item) => {
          return item.id === this.currentStatementId;
        });
      }
      return statement;
    },
    statementYear() {
      let year = "2021-2022";
      if (this.currentStatement) {
        year = `${this.currentStatement.year - 1}-${
          this.currentStatement.year
        }`;
      } else {
        year = `${moment().subtract(1, "years").year}-${moment().year}`;
      }
      return year;
    },
    allFields() {
      return parseChildrenTrees(
        Object.values(this.currentProject?.settings?.trees?.data)
      );
    },
    pointsCount() {
      return (
        this.achievementsList?.reduce((acc, i) => {
          return acc + i.project_tree_points;
        }, 0) ?? 0
      );
    },
    ...mapGetters(["projectSettings"]),
    ...mapState("user", ["user", "disabledFields"]),
    ...mapState([
      "portfolioList",
      "statementsList",
      "currentCategoryData",
      "statementId",
      "achievementsList",
      "isOldApplication",
      "isOnAchievementsPage",
      "isDeleteBtnLoading",
      "storage",
    ]),
  },
  methods: {
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupRevokeApplication",
        isShown: true,
        props: {
          id: this.statementId
            ? this.statementId
            : this.currentCategoryData?.id,
          fromProject: true,
        },
      });
    },
    saveAchievement(value) {
      this.achievementIsShown = false;
      this.setCurrentTreeItem(value);
    },
    addAchievement() {
      this.achievementIsShown = true;
    },
    loadStatementsLit() {
      if (Object.values(this.currentCategoryData)?.length === 0) {
        this.isStatementsLoading = true;
        createRequest(requestConfigs.GETStatementList)
          .then((response) => {
            this.$store.commit("setStatementsList", response.data);
            this.$store.dispatch(
              "getAchievementsList",
              this.statementId || this.currentCategoryData.id
            );
          })
          .finally(() => {
            this.isStatementsLoading = false;
          });
      } else
        this.$store.dispatch(
          "getAchievementsList",
          this.statementId || this.currentCategoryData.id
        );
    },
    async sendStatements() {
      this.isBtnLoading = true;
      if (this.noneAchievements) {
        this.$store.commit("pushToTray", {
          text: "Необходимо прикрепить не менее 1 документа к заявке",
          type: "error",
        });
        return;
      }
      await createRequest(requestConfigs.POSTSendStatement, {
        routerPayload: { id: this.statementId || this.currentCategoryData.id },
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Заявка успешно создана",
            type: "success",
          });
          this.$store.commit("setStatementId", "");
          this.$router.push({ name: "Applications" });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    setCurrentTreeItem(value) {
      this.currentTreeItem = this.parseParentTrees(this.allFields, value)
        .reverse()
        .map((item, index) => {
          item.field_name = this.fieldsName[index];
          return item;
        });
    },
    parseParentTrees,
    moment,
  },
  beforeMount() {
    if (this.isOnAchievementsPage) return;
    createRequest(requestConfigs.GETPortfolioList).then((response) => {
      this.$store.commit("setPortfolioList", response.data);
      this.$store.commit("setIsOnAchievementsPage", true);
    });
    this.loadStatementsLit();
  },
};
</script>

<style lang="scss">
.social-active-achievements {
  &__item {
    margin-bottom: 25px;
    @include adaptive(phone) {
      margin-bottom: 20px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    margin-bottom: 10px;
  }
  &__warning {
    font-weight: 500;
    color: var(--red);
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 25px;
    @include link();
    @include adaptive(phone) {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 20px;
    }
  }
  &__toggle {
    color: var(--background--btn--red);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    cursor: pointer;
    padding: 16px 21px;
    display: flex;
    gap: 13px;
    border-bottom: 1px solid var(--background--btn--profile--hover);
    border-top: 1px solid var(--background--btn--profile--hover);
    margin-bottom: 23px;
    @include adaptive(phone) {
      margin-bottom: 15px;
    }
    &:before {
      content: "\e90d";
      transform: rotate(45deg);
      font-family: icomoon;
      width: 14px;
      height: 14px;
    }
  }

  &__btn {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}
</style>
